import { PUBLIC_BACKEND, PUBLIC_DEBUG } from "$env/static/public";
import { protocol } from "$lib/secure";

import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';


var handler = (/** @type {any} */ error) => {
  if (PUBLIC_DEBUG === "true") console.log(error);
};

// if (PUBLIC_DEBUG === "true") console.log("PUBLIC_BACKEND: ", PUBLIC_BACKEND);

if (!PUBLIC_BACKEND.includes("localhost")) {
  Sentry.init({
    dsn: 'https://da45e3384354861fa108f2a6403dc84e@o4507167205621760.ingest.de.sentry.io/4507167208833104',
    tracesSampleRate: 1.0,
  
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    
    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration()],
  });

  handler = handleErrorWithSentry;
}

// if (PUBLIC_DEBUG === "true") console.log(handler);
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handler;
